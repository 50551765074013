@mixin mittig {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin abrunden {
  box-sizing: border-box;
  border-radius: 14px;
}

@mixin glassmorphism {
  background: rgba( 229, 229, 229, 0.6 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
}
