.windgeschwindigkeit {
  position: absolute;
  top: 50px;
  right:  0;
  width: 50px;
  text-align: center;
  font-weight: bold;
  font-size: 15px;
  button {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 25px;
    height: 30px;
  }
}
.wind-pfeil {
  position: absolute;
  scale: 0.5;
  top: 0;
  right: -8px;
  transition-duration: 0.8s;
  transition-property: transform;
}
