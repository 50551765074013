
/** Animationen **/

.fade-in-left {
  -webkit-animation: fadeInLeft 0.5s ease forwards;
  animation: fadeInLeft 0.5s ease forwards;
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%);
    display: none;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
    display: block;
  }
}

.fade-out-left {
  -webkit-animation: fadeOutLeft 0.5s ease forwards;
  animation: fadeOutLeft 0.5s ease forwards;
}

@keyframes fadeOutLeft {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-100%);
    display: none;
    width: 0;
    visibility: hidden;
  }
}
