@import 'primeflex/primeflex.scss';

.streu-label {
  @include styleclass('block font-medium mb-2');
  color: var(--brand-text);
  img {
    top: 6px;
    position: relative;
    opacity: 0.7;
  }
}

.field {
  div {
    @include styleclass('brand-text font-medium');
  }
}


.p-dialog {
  @include glassmorphism;
  .p-dialog-content, .p-dialog-header {
    background: inherit;
  }
}

.p-slider {
  background: #e1e1e1;
}

.auswahl-box {
  color: var(--secondary-color);
  background: var(--surface-100);
  height: 80px;
  margin: 5px;
  padding: 5px;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-large);
  word-break: break-word;
  word-wrap: break-word;
  overflow: hidden;
  flex: 1 1 0;
  @include mittig;
  @include abrunden;

  &.selektiert {
    color: var(--secondary-color);
    background: var(--primary-100);
    outline: 2px solid var(--primary-color);
  }
}

.ueberschrift {
  width: 100%;
  text-align: center;
  font-size: var(--font-size-normal);
  margin-top: 15px;
  margin-bottom: 5px;

  color: var(--gray-500);
}

.slider {
  width: 100%;
  height: 15px;
  border-radius: 5px;
}

.default-slider {
  top: 5px;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: none;
  &.p-disabled, .p-component:disabled {
    opacity: 1;
  }

  .p-slider-range {
    background: none;
  }
  .p-slider-handle {
    background: none;
    border-width: 0 10px 22px 10px;
    border-color: transparent transparent var(--brand-green) transparent;
  }
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background-color: var(--brand-orange);
}
