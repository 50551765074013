/* You can add global styles to this file, and also import other style files */

/**
 * PrimeNG Theme und Komponenten
 */
@import 'primeflex/primeflex.scss';
@import "primeng/resources/primeng.css";
@import "assets/scss/theme_alternative.css";
@import "primeicons/primeicons.css";

/**
 * Allgemeine Stylings
 */
@import "assets/scss/animation";
@import "assets/scss/ios";
@import "assets/scss/mixins";
@import "assets/scss/basis";

/**
 Spezielle Komponenten
 */
@import "assets/scss/custom";
@import "assets/scss/pad";
@import "assets/scss/speeddail";
@import "assets/scss/loader";
@import "assets/scss/aufgabepunkt";
@import "assets/scss/windsteuerung";

/**
 * Amazone Brand
 */
@import "assets/scss/amazone_brand";
