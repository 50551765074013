html {
  overscroll-behavior: none;
  background: var(--surface-0);
}

body {
  font-family: Camingo, Helvetica, Arial, sans-serif;
  color: #707070;
  margin: 0;
  padding: 0;
  width: 100vw;
  background-color: var(--surface-ground);
}

a {
  color: var(--primary-color);

  &:hover {
    color: var(--gray-500);
  }
}

hr {
  height: 1px;
  background-color: var(--gray-500);
  border: none;
}

.pi {
  top: 1px;
  position: relative;
}

.p-card {
  @include styleclass('shadow-4');
}

.p-slider:not(.p-disabled) .p-slider-handle:hover {
  background: var(--brand-orange);
  border-color: var(--brand-orange)
}

.cmsMode {
  height: 130px !important;
  @media (max-width: 1200px) {
    height: 70px !important;
  }
}

.cmsModeMargin {
  margin-top: 130px !important;
  @media (max-width: 1200px) {
    margin-top: 70px !important;
  }
}

.rounded {
  border-radius: 14px;
}


.background {
  background-image: url(../images/feld.jpeg);
  background-size: cover;
}
