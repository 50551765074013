.p-speeddial {
  position: fixed !important;
}

.p-speeddial-direction-up {
  left: calc(50% - 2rem);
  top: 0;
}

.p-speeddial-direction-down {
  left: calc(50% - 2rem);
  bottom: 0;
}

.p-speeddial-direction-left {
  left: 0;
  top: calc(50% - 2rem);
}

.p-speeddial-direction-right {
  right: 0;
  top: calc(50% - 2rem);
}

.p-speeddial-direction-up-left {
  left: 0;
  top: 0;
}

.p-speeddial-direction-up-right {
  right: 0;
  top: 0;
}

.p-speeddial-direction-down-left {
  left: 15px;
  bottom: 30px;
}

.p-speeddial-direction-down-right {
  right: 15px;
  bottom: 30px;
}
