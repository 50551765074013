:root {
  --brand-green: #387D33;
  --brand-orange: #ff6900;
  --brand-orange-80: #ff8900;
  --brand-grey: #3f4e55;
  --brad-beige: #F5E1A4;
  --brand-text: #383e42;
}

.brand-orange {
  color: var(--brand-orange) !important;
}

.brand-text {
  color: var(--brand-text) !important;
}


.amazone-logo {
  background: #ff6900;
  clip-path: polygon(0 0,100% 0,90% 100%,0 100%);
  -webkit-clip-path: polygon(0 0,100% 0,90% 100%,0 100%);
  left: 0;
  padding: 5px 55px 0 5px;
  position: absolute;
  top: 0;
  .text {
    color: #fff;
    top: -5px;
    position: relative;
    left: 12px;
  }
}
