.drehscheiben {
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
}

.drehscheibe {
  margin: 5px;
  width: 60px;
}

.drehscheibe-punkt, .drehscheibe-punkt-gespiegelt {
  position: absolute;
  top: 10px;
  left: 27px;
  width: 15px;
  z-index: 100;
  transform-origin: 8px 14px;
}

.drehscheibe-gespiegelt {
  margin: 5px;
  width: 60px;
  transform: scaleX(-1);
}

.aufgabepunkt-buttons {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  text-align: center;
  font-weight: bold;
  font-size: 15px;

  button {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 25px;
    height: 30px;
  }
}
