.loader {
  position: absolute;
  top: calc(50% - 100px);
  right: calc(50% - 100px);
  margin-top: 15px;
  width: 200px;
  height: 200px;
  border: 10px solid;
  border-color: var(--brand-orange-80) transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
  background: #3f4e5580;
}

.loader-cms {
  position: absolute;
  top: 130px;
  right: 5px;
  margin-top: 15px;
  width: 40px;
  height: 40px;
  border: 5px solid;
  border-color: var(--brand-orange-80) transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
  @media (max-width: 1200px) {
    top: 70px;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
